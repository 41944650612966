<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6985 12.6684L18.4448 19.1091C18.653 19.4672 19.1121 19.5886 19.4702 19.3804C19.8282 19.1721 19.9497 18.713 19.7414 18.355L15.7924 11.5656C15.326 10.7637 14.1793 10.7314 13.6685 11.5058L11.1029 15.3953L9.58381 14.0553C9.01799 13.5562 8.14232 13.6684 7.72052 14.2939L5.01074 18.3128C4.77917 18.6562 4.86986 19.1223 5.21329 19.3539C5.55673 19.5855 6.02286 19.4948 6.25443 19.1513L8.80506 15.3686L10.7577 17.091C10.9206 17.2346 11.1377 17.3006 11.3529 17.2719C11.5681 17.2433 11.7604 17.1227 11.8799 16.9415L14.6985 12.6684Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.50732 5.24986C6.05758 5.24986 4.88232 6.42512 4.88232 7.87486C4.88232 9.32461 6.05758 10.4999 7.50732 10.4999C8.95707 10.4999 10.1323 9.32461 10.1323 7.87486C10.1323 6.42512 8.95707 5.24986 7.50732 5.24986ZM6.38232 7.87486C6.38232 7.25354 6.886 6.74986 7.50732 6.74986C8.12864 6.74986 8.63232 7.25354 8.63232 7.87486C8.63232 8.49618 8.12864 8.99986 7.50732 8.99986C6.886 8.99986 6.38232 8.49618 6.38232 7.87486Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.25 3C1.25 2.0335 2.0335 1.25 3 1.25H21C21.9665 1.25 22.75 2.0335 22.75 3V21C22.75 21.9665 21.9665 22.75 21 22.75H3C2.0335 22.75 1.25 21.9665 1.25 21V3ZM3 2.75C2.86193 2.75 2.75 2.86193 2.75 3V21C2.75 21.1381 2.86193 21.25 3 21.25H21C21.1381 21.25 21.25 21.1381 21.25 21V3C21.25 2.86193 21.1381 2.75 21 2.75H3Z"
      fill="currentColor" />
  </svg>
</template>
